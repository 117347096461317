

.react-calendar {
    width: 100%;
    height: fit-content;
    font-family: 'SUIT';
    line-height: 1.125em;
    background: #fff;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    color: #262626;
    cursor: default;
}

.react-calendar--doubleView {
    font-family: 'SUIT';
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    font-family: 'SUIT';
    display: flex;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    font-family: 'SUIT';
    color: #262626;

    width: 50%;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    font-family: 'SUIT';
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    color: #262626;

}

.react-calendar__navigation__prev-button {
    margin: -8px 0 0 0;
    transform: scale(1.2, 1.5);
    color: #C6C6C6;
}

.react-calendar__navigation__next-button {
    margin: -8px 0 0 0;
    transform: scale(1.3, 1.5);
    color: #C6C6C6;
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__label__labelText {
    font-family: 'SUIT';
    font-size: 18px;
    font-weight: 600;
}


.react-calendar button {
    font-family: 'SUIT';
    box-sizing: border-box;
    min-height: 50px;
    border: 0;
    outline: none;
    background: #fff;
    color: #262626;
    cursor: default;
}

.react-calendar button:enabled:hover {
    cursor: default;
}

.react-calendar__navigation {
    font-family: 'SUIT';

    display: flex;

    width: 300px;
    margin: 8px auto;
    color: #262626;

}

.react-calendar__navigation button {
    font-family: 'SUIT';
    font-weight: 600;

    font-size: 16px;
    line-height: 15px;
    min-width: 20px;
    background: none;
    color: #262626;

}

.react-calendar__navigation button:disabled {
    font-family: 'SUIT';
    color: #262626;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    font-family: 'SUIT';
}

.react-calendar__month-view__weekdays {
    font-family: 'SUIT';
    text-align: center;
    text-transform: uppercase;
    /* font-weight: bold; */
    font-size: 0.75em;
    color: #A8A8A8;
}

.react-calendar__month-view__weekdays__weekday {
    font-family: 'SUIT';
    padding: 0.5em;
    color: #A8A8A8;
}

.react-calendar__month-view__weekdays__weekday abbr {
    font-family: 'SUIT';
    color: #A8A8A8;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    font-family: 'SUIT';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day {
    padding: 0;
    margin: 0;
}


.react-calendar__month-view__days__day--weekend {
    font-family: 'SUIT';
    font-weight: 600;
    color: #A8A8A8;
}

.react-calendar__month-view__days__day--weekend abbr{
    font-family: 'SUIT';
    font-weight: 600;
    color: #A8A8A8;
}

.react-calendar__month-view__days__day--neighboringMonth {
    font-family: 'SUIT';
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    font-family: 'SUIT';
    padding: 2em 0.5em;
    background: #F7F8FA;
}



/* 타일(일) */
.react-calendar__tile {
    font-family: 'SUIT';
    width: 100%;
    height: 64px;
    text-align: center;
    font-weight: 700;
    padding: none;
    color: #262626;

}

.react-calendar__month-view__days {
    /* border-top: 1px solid #E0E0E0; */
    margin-top: -4px;
}

.react-calendar__month-view__days button {
    color: #262626;
    border-top: 2px solid #fff;
    /* border-top:none; */
}

.react-calendar__tile:disabled {
    font-family: 'SUIT';
    color: #262626;
}

.react-calendar__tile--now {
    /* border-top: 2px solid #15AE21 !important; */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    font-family: 'SUIT';
    font-weight: 700;
    /* border-top: 2px solid #A8A8A8; */
}


.react-calendar__tile--now abbr {
    font-family: 'SUIT';
    font-weight: 700;
    background: #15AE21;
    padding: 0 4px;
    border-radius: 10px;
    color: #fff;
}



.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    font-family: 'SUIT';
    
}



.react-calendar__tile--hasActive {
    font-family: 'SUIT';
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    font-family: 'SUIT';
    
}

.react-calendar__tile--active {
    font-family: 'SUIT';
    font-weight: 600;
}

/* .react-calendar__tile--active abbr {
    font-family: 'SUIT';
    font-weight: 600;
    background: #A8A8A8;
    padding: 0 4px;
    border-radius: 8px;
    color: #262626;
} */

/* .react-calendar__tile--active button {
    border-top: 2px solid #A8A8A8;
} */

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    font-family: 'SUIT';
}

.react-calendar--selectRange .react-calendar__tile--hover {
    font-family: 'SUIT';
    background-color: #e6e6e6;
}

