.WebFullFrame {
  box-sizing: border-box;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
}

.MobileFullFrame {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  z-index: 999;
}

.frame { 
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  vertical-align: middle;
  top: 0;
  transform: scale(1.13, 10);
  width: 360px;
  height: 100%;
  margin: 0 auto;
  z-index: 2000;
}

@media screen and (max-width: 500px) {
  .MobileFullFrame {
    position: inherit;
    width: 100%;
    height: 100vh;
    min-width: 340px;
  }
  .frame {
    display: none;
  }
}

@media screen and (max-height: 600px) {
  .frame {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .WebFullFrame {
    top: 50%;
    transform: translate(-40%, -50%);
    max-width: 375px;
  }
}

@media screen and (min-width: 900px) {
  .WebFullFrame {
    top: 50%;
    left: 40%;
    transform: translate(-15%, -50%);
  }
}


@media screen and (min-width: 1024px) {
  .WebFullFrame {
    top: 50%;
    left: 55%;
    transform: translate(-5%, -50%);
  }
}

@media screen and (min-width: 1280px) {
  .WebFullFrame {
    top: 50%;
    left: 55%;
    transform: translate(-5%, -50%);
  }
}

@media screen and (min-width: 1700px) {
  .WebFullFrame {
    top: 50%;
    left: 55%;
    transform: translate(30%, -50%);
  }

}

@media screen and (min-width: 2000px) {
  .WebFullFrame {
    top: 50%;
    left: 55%;
    transform: translate(50%, -50%);
  }

}

.Container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 999;
}

.Container::-webkit-scrollbar {
  display: none;
}